var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"row--35",attrs:{"align":"start"}},[_c('v-col',{attrs:{"lg":"6","md":"6","sm":"12","cols":"12","order":"2","order-md":"1"}},[_c('div',{staticClass:"section-title text-left mb--50 mb_sm--30 mb_md--30"},[_c('h2',{staticClass:"heading-title"},[_vm._v("Contact Us.")]),_c('p',{staticClass:"description"},[_vm._v(" We will get back to you soon. ")])]),_c('div',{staticClass:"form-wrapper",attrs:{"id":"formContact"}},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('ValidationProvider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.name),expression:"formData.name"}],attrs:{"type":"text","placeholder":"Your Name *"},domProps:{"value":(_vm.formData.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "name", $event.target.value)}}}),_c('span',{staticClass:"inpur-error"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.email),expression:"formData.email"}],attrs:{"type":"text","rules":"required|email","placeholder":"Your email *"},domProps:{"value":(_vm.formData.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "email", $event.target.value)}}}),_c('span',{staticClass:"inpur-error"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"subject","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.subject),expression:"formData.subject"}],attrs:{"type":"text","placeholder":"Write a Subject"},domProps:{"value":(_vm.formData.subject)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "subject", $event.target.value)}}}),_c('span',{staticClass:"inpur-error"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"message","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.message),expression:"formData.message"}],attrs:{"placeholder":"Your Message"},domProps:{"value":(_vm.formData.message)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "message", $event.target.value)}}}),_c('span',{staticClass:"inpur-error"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)}),_c('button',{staticClass:"rn-button-style--2 btn_solid",attrs:{"type":"submit","value":"submit"}},[_vm._v(" Submit ")])],1)]}}])})],1),_c('div',{staticStyle:{"display":"none"},attrs:{"id":"thankyouMessage"}},[_vm._v(" Thanks for submitting your information. We will get back to you soon. ")])]),_c('v-col',{attrs:{"lg":"6","md":"6","sm":"12","cols":"12","order":"1","order-md":"2"}},[_c('div',{staticClass:"thumbnail mb_md--40 mb_sm--40"},[_c('img',{attrs:{"src":require("../../assets/images/portfolio/4.jpg"),"alt":"trydo"}})])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }